export const bookingData = [
    {
      property: 'Thieves Garden',
      startDate: new Date('2024-12-17'),
      endDate: new Date('2024-12-20'),
      status: 'booked',
      booking: {
        name: 'Colin Mackay',
        email: 'colinjohnmackay49@gmail.com',
        source: 'direct'
      },
      comments: 'Visiting family. Repeat renter'
    },
    {
      property: 'Thieves Garden',
      startDate: new Date('2024-12-20'),
      endDate: new Date('2024-12-24'),
      status: 'tentative',
      comments: 'Need time to clean'
    },
    {
      property: 'Thieves Garden',
      startDate: new Date('2025-01-05'),
      endDate: new Date('2025-01-26'),
      status: 'booked',
      booking: {
        name: 'Simon Nagy',
        email: 'simon.nagy@hotmail.com',
        source: 'direct'
      },
      comments: 'Coming from Australia to work as a teacher'
    },
    {
      property: 'Thieves Garden',
      startDate: new Date('2025-06-08'),
      endDate: new Date('2025-06-20'),
      status: 'booked',
      booking: {
        name: 'Holly Williams',
        source: 'Airbnb'
      },
      comments: 'Will need sheets for the sofa bed for the middle weekend.'
    }
  ];