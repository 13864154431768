export default function BookingsIntro() {
  return (
    <>
      <div id='bookings-intro' className='banner-image-text'>
        <h1>Booking the apartment</h1>
        <img
          src="../../images/outside/Oleron_Outside_House_1442.jpg"
          alt=""
          className='full-width-image'
        />
      </div>
      <div className='responsive-text'>
        <h2>Check availability, select which dates you're interested in booking, add your details, and then submit the form. We'll get in touch with you as soon as possible to answer any questions and confirm the booking.</h2>
      </div>
    </>
  );
}